import {createStore, applyMiddleware} from 'redux';
import thunk from 'redux-thunk';
import { persistReducer } from 'redux-persist'
import { rootReducer } from '.';
import storage from 'redux-persist/lib/storage'

const persistConfig = {
    key: 'root',
    storage,
  }

const persistedReducer = persistReducer(persistConfig, rootReducer)

  // export default () => {
  //     let store = createStore(persistedReducer,applyMiddleware(thunk))
  //     let persistor = persistStore(store)
  //     return { store, persistor }
  //   }
  export const  Store = createStore(
      persistedReducer,
      applyMiddleware(thunk)
  )
// export const Store = createStore(
//     rootReducer,
//     applyMiddleware(thunk)
// )