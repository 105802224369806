import {LOGINSUCCESS, LOGINFAIL, LOGOUT} from '../actions/index';
const initial = {
    token : null,
    message:'',
    admin_id : null,
    hit : false,
    flag : null
}


export const LoginReducer = (state = initial, action)=>{
    switch(action.type){
        case LOGINSUCCESS:
            console.log(action);
            return {
                ...state,
                token : action.payload.token,
                admin_id : action.payload.admin_id,
                message : 'User Login SuccessFull',
                hit : true,
                flag : 'SUCCESS'

            }
        case LOGINFAIL:
            return{
                ...state,
                message : action.payload.error,
                hit : true,
                flag : 'ERROR'
            }
        case LOGOUT:
            console.log('here')
            return{
                ...state,
                token : null,
                user_id : null,
                type : '',
                message : action.payload.data,
                hit : true,
                flag : 'SUCCESS'
            }
        default : return state;
    }
}